import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Button, Box , Typography ,useMediaQuery , Divider , Dialog, DialogTitle, DialogContent, DialogActions}  from '@mui/material';
import DateTimeSelection from './DateTimeSelection';
import ResumeUpload from './ResumeUpload';
import BookingForm from './BookingForm';
import SuccessPage from './SuccessPage';
import { POST_BOOKING } from '../../api/booking';
import {useNavigate} from 'react-router-dom'
import axios from 'axios';

const steps = ['Select Date & Time', 'Upload Resume', 'Fill Details', 'Success'];
const dividerStyle = {
  bgcolor: '#FFBD59',
  height: '2px',
  width:'50%',
};
const MockInterview = () => {
  const [activeStep, setActiveStep] = useState(0);
  const isAuth = window.localStorage.isAuth || false;
  const [formData, setFormData] = useState({
    date: '',
    timeSlot: '',
    resume: null,  // Initialize as null for file handling
    name: '',
    course: '',
    college: '',
    year: '',
    email: '',
    whatsappNumber: ''
  });
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
  const [open, setOpen] = useState(isAuth);
  const navigate = useNavigate();

  // Handle navigation to login
  const handleLoginRedirect = () => {
    navigate('/login', { state: { redirect: '/mockInterview' } });
  };
  console.log(isAuth , !!isAuth)
  if (isAuth === false) {
    return (
      <Box>
        <Dialog open={true}>
          <DialogTitle>Login Required</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              Please log in first to apply for a mock interview.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              style={{ backgroundColor: '#6a58c6', color: 'white' }}
              onClick={handleLoginRedirect}
            >
              Login
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
  // On form submit (Step 3)
  const handleSubmit = () => {
    const form = new FormData();
    form.append('date', formData.date);
    form.append('timeSlot', formData.timeSlot);
    form.append('name', formData.name);
    form.append('course', formData.course);
    form.append('college', formData.college);
    form.append('year', formData.year);
    form.append('email', formData.email);
    form.append('whatsappNumber', formData.whatsappNumber);
  
    if (formData.resume) {
      form.append('resume', formData.resume);
    }
  
    axios.post(`${POST_BOOKING}${window.localStorage.id}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log('Booking submitted successfully:', response);
      handleNext();  // Go to success page
    })
    .catch(error => {
      console.error('Error submitting booking:', error);
    });
  };
  

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <DateTimeSelection formData={formData} setFormData={setFormData} />;
      case 1:
        return <ResumeUpload formData={formData} setFormData={setFormData} />;
      case 2:
        return <BookingForm formData={formData} setFormData={setFormData} onSubmit={handleSubmit} />;
      case 3:
        return <SuccessPage />;
      default:
        return <div>Unknown step</div>;
    }
  };

  
  return (
    <Box sx={{ width: {xs: '100%' , md: '50%'}, margin: '160px auto' }}>

        <Typography variant="h4" fontWeight="800" style={{ color: '#000',  textAlign:'center' }}>
         Pre Placement Mock
        </Typography>
        <Typography variant="h4" fontWeight="800" style={{ color: '#6a58c6' ,   textAlign:'center' , display: 'flex' , alignItems: 'center' , flexDirection: 'column' , marginBottom: '40px'}}>
          Testimonials
          <Divider sx={dividerStyle} />
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
  {steps.map((label, index) => (
    <Step key={index} sx={{marginTop: '40px'}}>
      <StepLabel
        StepIconProps={{
          sx: {
            color: activeStep === index ?'#6a58c6 !important' : '#bdbdbd !important', // Ensures that the color is applied
          }
        }}
      >
        {label}
      </StepLabel>
    </Step>
  ))}
</Stepper>

      <div >
        {renderStepContent(activeStep)}
       {activeStep !== 3 && (
        <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: !activeStep === steps.length - 2 ? 'space-between' : 'flex-end',
        marginTop: 2 ,
        width: '100%'
      }}>
          {activeStep > 0 && (
            <Button onClick={handleBack} sx={{ marginRight: 1 , borderColor: '#6a58c6' , color: '#6a58c6' }}>
              Back
            </Button>
          )}
          {activeStep === steps.length - 2 ? (
            <Button variant="contained" onClick={handleSubmit} sx={{backgroundColor: '#6a58c6' , alignSelf: 'flex-end'}}>
              Submit
            </Button>
          ) : (
            <Button variant="contained" sx={{backgroundColor: '#6a58c6' , alignSelf: 'flex-end'}} onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          )}
        </Box>
       )}
      </div>
    </Box>
  );
};

export default MockInterview;
