const { BASE_URL } = require("./client");


const ORG_REGISTER = BASE_URL + '/api/org/signup';
const ORG_LOGIN = BASE_URL + '/api/org/login';
const ORG_UPLOAD = BASE_URL + '/api/org/upload/';
const ORG_FETCH = BASE_URL + '/api/org/fetch/';






export {ORG_REGISTER , ORG_LOGIN , ORG_UPLOAD , ORG_FETCH};